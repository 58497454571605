import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { MDXProvider } from '@mdx-js/react';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Section from '../components/Section';
import PageTitle from '../components/PageTitle';
import UniversalLink from '../components/UniversalLink';
import Icon from '../components/Icon';
import CodeOfConduct from '../code-of-conduct/code-of-conduct.md';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Layout>
    <Section>
      <Container>
        <PageTitle hidden>Code of Conduct</PageTitle>
        <MDXProvider components={{
        a: UniversalLink,
        h1: ({
          children,
          ...rest
        }) => <h2 className="title is-2" {...rest}>
                {children}
              </h2>,
        h2: ({
          children,
          ...rest
        }) => <h3 className="title is-3" {...rest}>
                {children}
              </h3>
      }}>
          <div className="content">{children}</div>
        </MDXProvider>
      </Container>
    </Section>
  </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <CodeOfConduct mdxType="CodeOfConduct" />
    <hr></hr>
    {
      /* prettier-ignore */
    }
    <p>{`Contribute to our `}<a parentName="p" {...{
        "href": "https://github.com/suncoast-devs/code-of-conduct"
      }}>{`Code of Conduct `}<Icon i="fab fa-github" mdxType="Icon" /></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      